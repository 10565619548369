import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface SetTimerProps {
  setTimer?: Dispatch<SetStateAction<boolean>>;
  dataString: string | undefined;
}

const Timer = ({ dataString }: SetTimerProps) => {
  const [timeLeft, setTimeLeft] = useState<string>("");
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    const getLeftTime = (dateString: string | undefined) => {
      if (dateString) {
        const now = new Date();
        const noon = new Date(dateString);

        const timeDifference = noon.getTime() - now.getTime();

        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
        const seconds = Math.floor((timeDifference / 1000) % 60);

        if (timeDifference > 0) {
          return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}:${String(seconds).padStart(2, "0")}`;
        } else {
          setIsStarted(true);
          return "Started";
        }
      } else {
        return "Alert: Invalid time";
      }
    };

    const interval = setInterval(() => {
      setTimeLeft(getLeftTime(dataString));
    }, 1000);

    return () => clearInterval(interval);
  }, [dataString]);

  return (
    <div className="w-full h-full py-10 bg-black bg-opacity-25 text-pointColor text-center">
      {isStarted ? null : <div>경기가 2시간 후 시작됩니다</div>}
      <div className="text-[70px] font-bold">{timeLeft}</div>
    </div>
  );
};

export default Timer;
