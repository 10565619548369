import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { PastMatch, prevMatchesProps } from "../../page/user/mypage";
import dayjs from "dayjs";

const PrevGameCard = (props: prevMatchesProps) => {
  const [matchTime, setMatchTime] = useState("0000. 00. 00. Fri");
  useEffect(() => {
    const time = dayjs(props.matchDate).format("YYYY. MM. DD. ddd");
    setMatchTime(time);
  }, []);
  return (
    <div className={`bg-cardBgColor rounded-xl overflow-hidden w-[300px]`}>
      <div className="bg-[#2B2B2B] px-5 py-3">
        <div>{matchTime}</div>
        <div className="text-[12px] opacity-50">{props.matchGround}</div>
      </div>
      <div className="grid grid-cols-[1fr_2fr_1fr] gap-1 px-5 py-5">
        <div className="flex flex-col gap-1 items-center justify-center">
          <div className="size-8 rounded-full bg-[#2B2B2B]" />
          <div className="text-[10px]">
            {props.winningCrew ? props.winningCrew : props.winningTeam}
          </div>
        </div>

        <div className="flex flex-col font-Montserrat px-2">
          <div className="flex justify-between text-3xl font-bold">
            <span>{props.winningScore}</span>
            <span>:</span>
            <span>{props.losingScore}</span>
          </div>

          <div className="flex text-[10px] justify-between text-[#7B7B7B]">
            <div className="flex items-center gap-1">
              <FaUser />
              {props.winTeamMember}
            </div>
            <div className="flex items-center gap-1">
              <FaUser />
              {props.loseTeamMember}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1 items-center justify-center">
          <div className="size-8 rounded-full bg-[#2B2B2B]" />
          <div className="text-[10px]">
            {props.losingCrew ? props.losingTeam : props.losingTeam}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrevGameCard;
