import { useNavigate } from "react-router-dom";
import NavBtn from "./navBtn";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { navState } from "../../atom";

export default function Navigation() {
  const navigate = useNavigate();
  const [page, setPage] = useRecoilState(navState);
  useEffect(() => {
    const locate = window.location.href;
    if (locate.includes("home")) {
      setPage("home");
    } else if (locate.includes("match")) {
      setPage("match");
    } else if (locate.includes("rank")) {
      setPage("rank");
      // } else if (locate.includes("rent")) {
      //   setPage("rent");
    } else if (locate.includes("mypage")) {
      setPage("mypage");
    } else {
      setPage("");
    }
  }, [window.location.href]);
  return (
    <div className="fixed bottom-0 w-screen bg-navBgBlack py-3 px-5 border-black rounded-t-xl shadow-xl shadow-black flex justify-between items-center bg-navBgColor">
      <span onClick={() => navigate("/home")}>
        <NavBtn label="홈" nav={"home"} />
      </span>
      <span onClick={() => navigate("/match")}>
        <NavBtn label="매칭" nav={"match"} />
      </span>
      <span onClick={() => navigate("/rank")}>
        <NavBtn label="랭킹" nav={"rank"} />
      </span>
      <span
        onClick={() =>
          alert(
            "준비중인 기능입니다!\n공만 챙겨주세요! 조끼는 @wekick_official 로 DM 주시면 위킥이 빌려드릴게요!"
          )
        }
      >
        <NavBtn label="장비대여" nav={"rent"} />
      </span>
      <span onClick={() => navigate("/mypage")}>
        <NavBtn label="마이페이지" nav={"mypage"} />
      </span>
    </div>
  );
}
