import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { HiXMark } from "react-icons/hi2";
import { MdCheck } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";

interface ApplyMatchProps {
  setIsMatch: Dispatch<SetStateAction<boolean>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

export default function ApplyMatch({
  setIsMatch,
  setIsModal,
}: ApplyMatchProps) {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { matchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isApplySuccess, setIsApplySuccess] = useState(false);

  useEffect(() => {
    const onApply = () => {
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/matches/${matchId}/apply`,
          { data: null },
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
            },
          }
        )
        .then((res) => {
          setIsApplySuccess(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };
    if (cookies.accessToken) {
      onApply();
    } else {
      alert("로그인이 필요해요!");
      navigate("/login");
    }
  }, []);
  return (
    <div className="fixed z-50 top-0 left-0 flex justify-center items-center px-1 py-2 bg-black bg-opacity-85 w-full h-full">
      {isLoading ? (
        <div>Loading...</div>
      ) : isApplySuccess ? (
        <div className="bg-cardBgColor w-[80%] h-[50%] px-2 py-4 flex flex-col justify-between rounded-2xl">
          <div
            className="flex justify-end px-2 w-full"
            onClick={() => {
              setIsMatch(false);
              setIsModal(false);
            }}
          >
            <HiXMark className="size-5 hover:bg-pointColor hover:text-black rounded-full" />
          </div>
          <div className="w-full flex flex-col gap-3 justify-center items-center ">
            <MdCheck className="bg-pointColor text-black size-16 p-3 rounded-full" />
            <div className="font-bold text-xl">신청 완료</div>
            <div className="opacity-65">즐거운 경기가 되시길 바랍니다.</div>
          </div>
          <div className="px-5 text-center text-[13px] opacity-45">
            *경기 매칭 마감(경기 2시간 전) 이후로 취소할 경우 패널티를 받을 수
            있습니다.
          </div>
        </div>
      ) : (
        <div className="bg-cardBgColor w-[80%] h-[50%] px-2 py-4 flex flex-col justify-between rounded-2xl">
          <div
            className="flex justify-end px-2 w-full"
            onClick={() => {
              setIsMatch(true);
              setIsModal(false);
            }}
          >
            <HiXMark className="size-5 hover:bg-pointColor hover:text-black rounded-full" />
          </div>
          <div className="w-full flex flex-col gap-3 justify-center items-center ">
            <HiXMark className="bg-warningColor text-black size-16 p-3 rounded-full" />
            <div className="font-bold text-xl">신청 실패</div>
            <div className="opacity-65">다시 로그인 후 신청해주세요!</div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="px-3 text-center text-[13px] opacity-45">
              *문제가 반복될 경우 운영팀에 문의 해주세요.
            </div>
            <Link
              to={"https://forms.gle/eUG37FHXtzmwoD7y7"}
              className="px-3 text-center text-[13px] opacity-45 underline-offset-2 underline"
            >
              문의하러 가기
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
