import React, { useEffect, useState } from 'react';
import { MdArrowOutward } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import football_player from "../assets/homeImgs/home_football_player_img.png"
import rankingImg from "../assets/homeImgs/home_ranking.png"
import lentImg from '../assets/homeImgs/home_lent.png';
import myPageImg from '../assets/homeImgs/home_myPage.png';
import Card from '../components/HomeCompos/Card';

import itsme from "../assets/sponsor/itsme_logo.png"
import mavrk from "../assets/sponsor/mavrk_logo.png"
import logo_white from "../assets/sponsor/logo-white.png"

import moment from 'moment';
import axios from 'axios';

const cardsInfo = [
    { text: "랭킹", imgUrl: rankingImg, url: "/rank" },
    { text: "장비 대여", imgUrl: lentImg, url: "/rent" },
    { text: "마이페이지", imgUrl: myPageImg, url: "/mypage" }
]

const Home = () => {
    const today = moment()
    const [todayMatchId, setTodayMatchId] = useState<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/matches/by-date?date=${today.clone().format("YYYY-MM-DD")}`).then(res => {
            const matchData = res.data.data.matchList

            for (const item of matchData) {
                if (!moment(item.matchTime).isBefore(moment())) {
                    setTodayMatchId(res.data.data.matchList[0]?.matchId)
                    break
                }

            }
        }).catch(err => console.log(err))

    }, [])
    return (
        <div className="pageContainer ">
            <div className=" relative">
                <div className='absolute -left-5 w-screen flex flex-col items-center text-xs px-2 py-4 bg-cardBgColor text-blurTextColor space-y-2'>
                    <div>
                        [ 베타 테스트 기간 안내 ]
                    </div>
                    <div className='text-center'>
                        WEKICK은 <span className='font-Pretendard_Bold text-pointColor'>9월 30일</span>까지 베타테스트 기간입니다(매치는 정상 진행)<br />
                        사용자 여러분의 소중한 의견을 인스타그램 <Link to="https://www.instagram.com/wekick_official/" className='text-pointColor font-Pretendard_Bold underline'>@wekick_official</Link>로 남겨주세요
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center pt-24 pb-14 ">
                    <div className="font-Montserrat text-[55px] text-pointColor font-bold">
                        WEKICK
                    </div>
                    <div className="font-Pretendard_SemiBold">
                        풋살의 모든 것을 한 곳에서
                    </div>
                </div>
                <div className="space-y-4 pb-9 ">
                    <Link to="/match" className="btn w-full h-[213px] bg-cover bg-[url(./assets/homeImgs/home_football_img.png)] rounded-lg text-white">
                        <div className=" pt-6">
                            <div className="flex justify-between items-center pb-4">
                                <span className="font-Pretendard_Bold text-pointColor text-2xl">경기 매칭 예약</span> <MdArrowOutward size={40} />
                            </div>
                            <div className="text-sm text-start">
                                대학생 풋살의 새로운 패러다임, <br /> WEKICK!
                            </div>
                        </div>
                    </Link>
                    <div onClick={() => {
                        if (todayMatchId !== null) {
                            navigate(`/match/detail/${todayMatchId}`)
                        } else {
                            alert('당장 참여할 수 있는 경기가 없습니다!')
                        }

                    }} className="btn bg-cardBgColor w-full h-[88px] rounded-lg relative px-4 text-white text-start">
                        <div className="h-full  flex justify-between items-center ">
                            <div className="text-sm">
                                참여할 수 있는 경기를<br /> 확인해 보세요
                            </div>
                            <img src={football_player} className="absolute right-8 top-0 h-full " />
                            <MdArrowOutward size={40} className="opacity-70" />
                        </div>
                    </div>

                </div>
                <div className="bg-black h-3 w-screen opacity-70 absolute bottom-0 -left-5" />
            </div>
            <div className="flex w-full justify-between pt-4 pb-8">
                {cardsInfo.map((card, index) =>
                    <Card key={index} text={card.text} imgUrl={card.imgUrl} url={card.url} />
                )}

            </div>
            <div className='mb-4 mt-14'>
                <div className=' text-center  space-y-4 '>
                    <span className='text-xs opacity-30'>
                        WEKICK과 함께하는 스폰서 기업입니다!
                    </span>
                    <div className='flex justify-center items-center space-x-8'>
                        <img src={mavrk} className='h-3' />
                        <img src={itsme} className='h-5' />
                        <img src={logo_white} className='h-5' />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Home;