import Button from "../../components/Button/Button";
import KakaoLogo from "../../assets/kakaoLogo.png";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const LOGIN_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=profile_nickname,profile_image,talk_message,talk_calendar`;
  return (
    <div className="container relative">
      <div className="absolute top-0 left-0 w-screen h-screen bg-[url(./assets/Hexagon.png)] bg-cover z-0 " />
      <div className="absolute top-0 left-0 w-screen h-screen bg-[url(./assets/CircleOpacity.png)] bg-cover z-10 " />

      <div className="relative z-20 px-4  flex flex-col h-screen">
        <div className="text-4xl mt-40 flex-grow leading-snug">
          <span className="font-Pretendard_Thin">안녕하세요</span>
          <br />
          <div className="font-Pretendard_Bold">
            <span className="text-pointColor">WEKICK</span>
            <span>입니다.</span>
          </div>
        </div>
        <div className="mb-10 space-y-4">
          <Link to={LOGIN_URL} className="relative">
            <button className="font-Pretendard_Bold w-full h-12 rounded-lg font-bold  btn bg-[#FFEC41] hover:bg-[#FFEC41] text-black">
              카카오 로그인
            </button>
            <img src={KakaoLogo} className="absolute h-6 w-6 top-0 left-2" />
          </Link>
          <div onClick={() => navigate("/home")}>
            <button className="font-Pretendard_Bold w-full h-12 rounded-lg font-bold  btn text-blackbtn !border-solid !border-[#767676] bg-inherit text-[#D1D1D1] ">
              비로그인 서비스 둘러보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
