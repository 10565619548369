import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Button from "../../components/Button/Button";
import { TbCircleNumber1Filled, TbCircleNumber2Filled } from "react-icons/tb";
import InputCircleSelect from "../../components/Input/InputCircleSelect";

export default function GradeMatch() {
  const { matchId } = useParams<{ matchId: string }>();
  const [selectedScore, setSelectedScore] = useState<number | string>("");
  const [selectedTeam, setSelectedTeam] = useState<string | number>("");
  const [matchData, setMatchData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [myTeamName, setMyTeamName] = useState<string | null>(null); // 현재 로그인한 사용자의 팀

  const numericMatchId = parseInt(matchId as string, 10); // matchId를 숫자로 변환

  const isButtonActive = selectedTeam !== "" && selectedScore !== "";

  useEffect(() => {
    const fetchMatchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/matches/${numericMatchId}/history`,
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
            },
          }
        );
        const data = response.data.data;

        setMatchData(data); // matchData를 설정

        // 현재 로그인한 사용자의 팀 찾기
        if (Array.isArray(data.teamList?.teamList)) {
          const userTeam = data.teamList.teamList.find((team: any) =>
            team.memberList.some((member: any) => member.memberId === data.currentMemberId)
          );

          if (userTeam) {
            setMyTeamName(userTeam.teamName); // 사용자의 팀 이름 저장
          } else {
            // currentMemberId가 teamList에 없는 경우 home으로 navigate
            alert("이 매치에 참여하지 않았습니다!");
            navigate("/home");
          }
        }
      } catch (error) {
        console.error("매치 데이터를 가져오는 중 오류 발생:", error);
        navigate("/home"); // 오류가 발생하면 home으로 리다이렉트
      } finally {
        setIsLoading(false);
      }
    };

    if (numericMatchId) {
      fetchMatchData();
    }
  }, [numericMatchId, cookies.accessToken, navigate]);

  const handleTeamChange = (team: string | number) => {
    setSelectedTeam(team);
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scoreValue = parseInt(e.target.value, 10); // 입력된 값을 숫자로 변환
    setSelectedScore(isNaN(scoreValue) ? "" : scoreValue); // NaN이면 빈 문자열을 저장
  };

  const formatMatchTime = (matchTime: string) => {
    const date = new Date(matchTime);
    const formatter = new Intl.DateTimeFormat("ko-KR", {
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = formatter.format(date);
    return `${formattedDate} 경기`;
  };


  const handleSubmit = () => {
    const userId = matchData.currentMemberId;

    if (matchData.teamFormationType === "INDIVIDUALS" ||  matchData.teamFormationType === "CREW_INDIVIDUALS") {
      navigate(`/grade/indiv/${numericMatchId}`, {
        state: {
          winningTeam: selectedTeam,
          selectedScore: Number(selectedScore),
          myTeam: selectedTeam,
          matchId: numericMatchId,
          userId: userId,
          matchData: matchData,
          crewList: matchData.crewList,
        },
      });
    } else if (matchData.teamFormationType === "CREW_CREW") {
      const payload = {
        userId: userId,
        matchId: numericMatchId,
        winningTeam: selectedTeam,
        myTeam: selectedTeam,
        score: Number(selectedScore),
        mannerKing: null,
        skillKing: null,
        socialKing: null,
        foulKing: null,
        badManner: null,
        noShow: null,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/match-results`, payload, {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        })
        .then(() => {
          const crewList = matchData.crewList;
          navigate(`/grade/crew/${numericMatchId}`, {
            state: {
              winningTeam: selectedTeam,
              selectedScore: Number(selectedScore),
              myTeam: selectedTeam,
              userId: userId,
              matchId: numericMatchId,
              crewList: crewList,
            },
          });
        })
        .catch((error) => {
          console.error("매치 평가 제출 중 오류 발생:", error);
          alert("매치 평가 제출 중 오류가 발생했습니다.");
        });
    }
  };

  if (isLoading) {
    return <div>로딩 중...</div>;
  }

  if (!matchData || !Array.isArray(matchData.teamList?.teamList)) {
    return <div>매치 데이터를 불러오는 중 오류가 발생했습니다.</div>;
  }

  return (
    <div className="pageContainer px-5">
      <div className="pt-[36px]">
        <div className="flex items-center justify-between">
          <div className="text-[20px] font-bold text-white/60 font-Pretendard_Bold">
            경기 평가
          </div>
          <div className="text-[14px] font-Pretendard_Regular text-[#5A5A5A]">
            {formatMatchTime(matchData.matchTime)}
          </div>
        </div>
        <div className="mt-[45px] text-[24px] font-Pretendard_Bold">
          <div>이번 매칭은</div>
          <div>만족스러우셨나요?</div>
        </div>

        <div className="mt-[48px]">
          <div className="text-[16px]">
            <div className="flex mb-[16px] items-center">
              <TbCircleNumber1Filled className="text-[24px] mr-2 font-Montserrat" color="#DAFF47" />
              <span className="font-Pretendard_Regular">승리한 팀을 선택해 주세요.</span>
            </div>
            {Array.isArray(matchData.teamList?.teamList) ? (
              <InputCircleSelect
                selectedItem={selectedTeam}
                onItemChange={handleTeamChange}
                title="승리팀 선택"
                itemList={matchData.teamList.teamList.map((team: any) => team.teamName)} // 팀 목록 사용
                placeholder="팀 선택"
              />
            ) : (
              <div>팀 데이터가 올바르지 않습니다.</div>
            )}
            <div className="mt-[16px] text-[14px] font-Pretendard_Regular opacity-50">
              소속팀: <strong>{myTeamName}</strong>
            </div> {/* 현재 로그인한 사용자의 팀 정보 표시 */}
            <div className="mt-[40px] mb-[20px]">
              <div className="flex mb-[16px] items-center ">
                <TbCircleNumber2Filled className="text-[24px] mr-2 font-Montserrat" color="#DAFF47" />
                <span className="font-Pretendard_Regular">우리팀의 스코어를 입력해 주세요.</span>
              </div>
              <div className="flex items-center justify-center">
                <input
                  type="number"
                  value={selectedScore}
                  onChange={handleScoreChange}
                  className="bg-cardBgColor rounded-xl h-[64px] w-[88px] py-[7px] text-[50px] text-center text-white font-Montserrat font-bold"
                />
                <div className="ml-[8px] text-[40px] text-white/40 font-Pretendard_Medium">점</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full fixed pr-10 bottom-0 pb-5 bg-backgroundColor">
        <Button text="다음" active={isButtonActive} onClick={isButtonActive ? handleSubmit : undefined} /> {/* 버튼 비활성화 처리 */}
      </div>
    </div>
  );
}
