

import { FiArrowUpRight } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import Button from "../components/Button/Button";

export default function StartPage() {
  const navigate = useNavigate()
  return (
    <div className='px-5'>
      <div className='absolute top-0 left-0 w-screen h-screen bg-[url(./assets/startPageImg.png)] bg-cover z-0' />

      <div className='relative z-10 h-screen flex flex-col justify-between'>
        <div className='font-Helvetica font-bold text-[78px] leading-none mt-[66px] '>
          <div>
            GOING<br />
          </div>
          <div>
            TO<br />
          </div>
          <div className='text-pointColor'>
            MATCH <br />
          </div>
          <div>
            FUTSAL!
          </div>
        </div>
        <div className='space-y-4 mb-10'>
          <div className='relative' onClick={() => navigate("/login")}>
            <Button text="GO WEKICK" active={true} className='flex justify-start text-start hover:bg-pointColor' />
            <FiArrowUpRight size={30} color='black' className='absolute right-2 top-2' />
          </div>

          <div className='relative'>
            <Link to="https://wekick-in-hufs.notion.site/WE-KICK-793b51903d0447ab93ec6ccd71872d42">
              <Button text="서비스 소개" active={false} className='flex justify-start text-start !border-solid text-[#D1D1D1] !border-[#DDDDDD] bg-black' />
              <FiArrowUpRight size={30} color='#D1D1D1' className='absolute right-2 top-2' />
            </Link>
          </div>


        </div>


      </div>

    </div>
  )
    ;
}

