import React, { useEffect, useState } from "react";
import GoBack from "../../components/Navigation/GoBack";
import { FaUser } from "react-icons/fa";
import PrevGameCard from "../../components/Mypage/PrevGameCard";
import axios from "axios";
import { useCookies } from "react-cookie";
import { prevMatchesProps } from "./mypage";
import { useLocation, useParams } from "react-router-dom";

const PrevMatches = () => {
  const [cookies] = useCookies();
  const [page, setPage] = useState(0);
  const [prevMatches, setPrevMatches] = useState<prevMatchesProps[] | null>(
    null
  );
  const [pageLen, setPageLen] = useState(5);

  const param = useParams();
  const { pathname, state } = useLocation();
  useEffect(() => {
    const api =
      pathname === "/mypage/prevMatches" || pathname.includes("/player/detail")
        ? `/record/member/${state?.id || param?.playerId}?page=${page}`
        : `/record/crew/${param.detailId}?page=${page}`;

    axios
      .get(`${process.env.REACT_APP_API_URL}${api}`)
      .then((res) => {
        setPrevMatches(res.data.content);
        setPageLen(res.data.totalPages);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <div className="py-7">
      <div className="">
        <GoBack />
      </div>
      <div className="px-5 py-5 text-xl">지난 경기를 확인해보세요.</div>
      <div className="flex flex-col items-center gap-5 px-5">
        {prevMatches ? (
          prevMatches.map((prop, i) => <PrevGameCard key={i} {...prop} />)
        ) : (
          <div>지난 매칭이 없습니다.</div>
        )}
      </div>
      <div className="flex items-center gap-5 w-full justify-center py-3">
        <div
          onClick={() => {
            if (page > 0) {
              setPage((prev) => prev - 1);
            }
          }}
          className="px-5 text-black py-2 rounded-md bg-pointColor active:scale-[0.95] transition"
        >
          이전
        </div>
        <div>{page}</div>
        <div
          onClick={() => {
            if (page < pageLen) {
              setPage((prev) => prev + 1);
            }
          }}
          className="px-5 text-black py-2 rounded-md bg-pointColor active:scale-[0.95] transition"
        >
          다음
        </div>
      </div>
    </div>
  );
};

export default PrevMatches;
