import React from 'react';
import { RankingCrewDataType, RankingPlayerDataType } from './renderRankingType';
import { SearchCrewType, SearchPlayerType } from '../../page/rank/rank';
import { ProfileImage } from './PlayerType';

import RED from "../../assets/ProfileImgs/ProfileImg1.png"
import YELLOW from "../../assets/ProfileImgs/ProfileImg2.png"
import BLUE from "../../assets/ProfileImgs/ProfileImg3.png"
import GREEN from "../../assets/ProfileImgs/ProfileImg4.png"

import CrewImg from "../../assets/default.png"

type RankingCardType = RankingPlayerDataType | RankingCrewDataType;
type SearchCardType = SearchPlayerType | SearchCrewType

type PlayerCardType = RankingCardType | SearchCardType

const PlayerCard = (item: PlayerCardType) => {

    const renderProfileImg = (profileImg: string) => {
        switch (profileImg) {
            case ProfileImage.RED: return RED
            case ProfileImage.YELLOW: return YELLOW
            case ProfileImage.BLUE: return BLUE
            case ProfileImage.GREEN: return GREEN
            default: return RED
        }
    }
    return (
        <div className='btn w-full h-full bg-cardBgColor p-0 '>
            <div className='flex w-full h-full items-center  py-2'>
                <div className='font-Montserrat font-semibold w-16 text-center text-xl border-r border-[#4E4E4E] py-2'>
                    {"rank" in item ? item.ranking : item.ranking === null ? "-" : item.ranking}
                </div>
                <div className='flex-grow  flex items-center'>
                    <img src={"nickName" in item ? renderProfileImg(item.profileImage) : CrewImg} className='ml-4 mr-[10px] h-10 w-10 rounded-full ' />
                    <div className='flex justify-between flex-grow items-center'>
                        <div className='text-start space-y-[6px] text-xs'>
                            <p className='opacity-70'>
                                {"nickName" in item ? item.nickName : item.name}
                            </p>
                            <p className='text-[#626262]'>
                                경기 수 {item.matchCount}
                            </p>
                        </div>
                        <div className='pr-4 font-Pretendard_Bold opacity-70'>
                            {item.rating} 점
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PlayerCard;