import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useCookies } from "react-cookie";

export default function AcceptInvite() {
  const { crewId, recruitCode } = useParams();
  const navigate = useNavigate();
  const [cookies] = useCookies(["accessToken"]);

  const checkAndAcceptInvite = async () => {
    if (!cookies.accessToken) {
      // 로그인되지 않은 경우
      alert("로그인이 필요한 기능입니다!");
      const redirectPath = `/crews/${crewId}/recruit/${recruitCode}/accept`;
      localStorage.setItem("crewAcceptRedirectUrl", redirectPath); // 현재 URL을 localStorage에 저장
      navigate(`/login`);  // 로그인 페이지로 이동
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/crews/${crewId}/recruit/${recruitCode}/accept`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        }
      );

      if (response.data.isSuccess) {
        alert("크루에 성공적으로 가입되었습니다.");
        navigate(`/crew/detail/${crewId}`);
      } else {
        alert(`가입 실패: ${response.data.message}`);
        navigate(`/mypage`);
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response && axiosError.response.status === 409) {
        alert("이미 가입한 크루가 존재합니다.");
        navigate(`/mypage`);
      } else {
        console.error("초대 수락 중 오류 발생:", axiosError);
        alert("초대 수락 중 오류가 발생했습니다.");
      }
    }
  };

  // useEffect(() => {
  //   // 로그인 완료 후 리다이렉트 URL로 이동
  //   const redirectUrl = localStorage.getItem("crewAcceptRedirectUrl"); // localStorage에서 URL 가져오기
  //   if (cookies.accessToken && redirectUrl) {
  //     navigate(redirectUrl); // 저장된 URL로 이동
  //     localStorage.removeItem("crewAcceptRedirectUrl"); // URL 초기화
  //   }
  // }, [cookies.accessToken, navigate]);

  useEffect(() => {
    // 초대 수락 요청 처리
    checkAndAcceptInvite();
  }, [crewId, recruitCode]);

  return <div>초대 수락 중...</div>;
}
