import moment, { Moment } from "moment";
import { atom } from "recoil";

export const navState = atom({
  key: "navState",
  default: "",
});

export const loginState = atom<boolean>({
  key: "loginState",
  default: false,
});

export const searchClickState = atom<boolean>({
  key: "searchClickState",
  default: false,
});

export enum MatchSexType {
  MALE = "MALE",
  FEMALE = "FEMALE",
  MIXED = "MIXED"
}

// MatchStatus 열거형 (Enum)
enum MatchStatus {
  RECRUITING = "RECRUITING",
  READY = "READY",
  CLOSED = "CLOSED"
}
export interface MatchGameCardType {
  matchId: number;
  matchTime: string;
  matchGround: string;
  matchType: MatchSexType;
  playerCount: number;
  matchStatus: MatchStatus;
}


export const selectedDateState = atom<moment.Moment>({
  key: "selectedDateState",
  default: moment()
})

export const rankTypeState = atom<"crew" | "player">({
  key: "rankTypeState",
  default: "player"
})

export const rankPageState = atom<number>({
  key: "rankPageState",
  default: 0
})
