import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Rent() {
  const navigate = useNavigate()

  useEffect(() => {
    alert("준비중인 기능입니다!")
    navigate(-1)
  }, [])
  return <div className="pageContainer">Rent</div>;
}
